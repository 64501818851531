var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("read", "camerasmanagement")
    ? _c(
        "div",
        { staticClass: "mb-2" },
        [
          _c(
            "b-card",
            { staticClass: "mb-0", attrs: { "no-body": "" } },
            [
              _c("cameras-management-list-filters", {
                attrs: {
                  "status-filter": _vm.statusFilter,
                  "status-options": _vm.statusOptions,
                  "camera-type-filter": _vm.cameraTypeFilter,
                  "camera-type-options": _vm.cameraTypeOptions
                },
                on: {
                  "update:statusFilter": function($event) {
                    _vm.statusFilter = $event
                  },
                  "update:status-filter": function($event) {
                    _vm.statusFilter = $event
                  },
                  "update:cameraTypeFilter": function($event) {
                    _vm.cameraTypeFilter = $event
                  },
                  "update:camera-type-filter": function($event) {
                    _vm.cameraTypeFilter = $event
                  }
                }
              }),
              _c(
                "div",
                { staticClass: "m-2" },
                [
                  _c("b-row"),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-start mb-1 mb-md-0",
                          attrs: { cols: "12", md: "6" }
                        },
                        [
                          _c("label", [_vm._v("Show")]),
                          _c("v-select", {
                            staticClass:
                              "per-page-selector d-inline-block mx-50",
                            attrs: {
                              dir: _vm.$store.state.appConfig.isRTL
                                ? "rtl"
                                : "ltr",
                              options: _vm.perPageOptions,
                              clearable: false
                            },
                            model: {
                              value: _vm.perPage,
                              callback: function($$v) {
                                _vm.perPage = $$v
                              },
                              expression: "perPage"
                            }
                          }),
                          _c("label", [_vm._v("entries")]),
                          _c(
                            "b-button",
                            {
                              staticClass: "ml-2",
                              attrs: {
                                variant: "primary",
                                disabled: this.$store.state.camerasManagement
                                  .fetchingCamerasManagementList
                              },
                              on: { click: _vm.refreshTheList }
                            },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "RefreshCcwIcon" }
                              }),
                              _c("span", { staticClass: "text-nowrap ml-1" }, [
                                _vm._v("Refresh")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-end"
                          },
                          [
                            _c("b-form-input", {
                              staticClass: "d-inline-block mr-1",
                              attrs: { placeholder: "Search..." },
                              model: {
                                value: _vm.searchQuery,
                                callback: function($$v) {
                                  _vm.searchQuery = $$v
                                },
                                expression: "searchQuery"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("b-table", {
                ref: "refListTable",
                staticClass: "position-relative",
                attrs: {
                  items: _vm.fetchItems,
                  responsive: "",
                  fields: _vm.tableColumns,
                  "primary-key": "camer_id",
                  "sort-by": _vm.sortBy,
                  "show-empty": "",
                  "empty-text": "",
                  "sort-desc": _vm.isSortDirDesc,
                  busy: _vm.isBusy
                },
                on: {
                  "update:sortBy": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sort-by": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sortDesc": function($event) {
                    _vm.isSortDirDesc = $event
                  },
                  "update:sort-desc": function($event) {
                    _vm.isSortDirDesc = $event
                  },
                  "update:busy": function($event) {
                    _vm.isBusy = $event
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(ID)",
                      fn: function(data) {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-nowrap" },
                            [
                              _c(
                                "b-link",
                                {
                                  staticClass:
                                    "font-weight-bold d-block text-nowrap",
                                  attrs: {
                                    to: {
                                      name: "apps-cameras-management-edit",
                                      params: { cameraid: data.item.camera_id }
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(data.item.camera_id) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(unit_number)",
                      fn: function(data) {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-nowrap" },
                            [
                              _c(
                                "b-link",
                                {
                                  staticClass:
                                    "font-weight-bold d-block text-nowrap",
                                  attrs: {
                                    to: {
                                      name: "apps-cameras-management-edit",
                                      params: { cameraid: data.item.camera_id }
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(data.item.unit_number) + " "
                                  )
                                ]
                              ),
                              _c("small", { staticClass: "text-muted" }, [
                                _vm._v(
                                  "Phone: " +
                                    _vm._s(data.item.phone) +
                                    " on " +
                                    _vm._s(data.item.cell_phone_carrier)
                                )
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(imei)",
                      fn: function(data) {
                        return [
                          _c("div", { staticClass: "text-nowrap" }, [
                            _c(
                              "span",
                              { staticClass: "align-text-top text-capitalize" },
                              [_vm._v(_vm._s(data.item.imei))]
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(client_display_name)",
                      fn: function(data) {
                        return [
                          _c("div", { staticClass: "text-nowrap" }, [
                            _c(
                              "span",
                              { staticClass: "align-text-top text-capitalize" },
                              [_vm._v(_vm._s(data.item.client_display_name))]
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(battery)",
                      fn: function(data) {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-nowrap" },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "mr-1",
                                style: _vm.resolveBatteryColor(
                                  data.item.current_status.battery
                                ),
                                attrs: {
                                  icon: _vm.resolveBatteryIcon(
                                    data.item.current_status.battery
                                  ),
                                  size: "lg"
                                }
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "align-text-top text-capitalize"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.resolveBatteryVariant(
                                        data.item.current_status.battery
                                      )
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(signal)",
                      fn: function(data) {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-nowrap" },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "mr-1",
                                style: _vm.resolveSignalColor(
                                  data.item.current_status.antenna_signal
                                ),
                                attrs: {
                                  icon: _vm.resolveSignalIcon(
                                    data.item.current_status.antenna_signal
                                  ),
                                  size: "lg"
                                }
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "align-text-top text-capitalize"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      data.item.current_status.antenna_signal
                                    ) + "dB"
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(firmware)",
                      fn: function(data) {
                        return [
                          _c("div", { staticClass: "text-nowrap" }, [
                            _c(
                              "span",
                              { staticClass: "align-text-top text-capitalize" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.resolveFirmwareVariant(
                                      data.item.current_status.firmware.version
                                    )
                                  )
                                )
                              ]
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(status)",
                      fn: function(data) {
                        return [
                          _c(
                            "b-badge",
                            {
                              staticClass: "text-capitalize",
                              attrs: {
                                pill: "",
                                variant:
                                  "light-" +
                                  _vm.resolveStatusVariant(
                                    data.item.is_active,
                                    data.item.is_online
                                  )
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.resolveStatusVariantText(
                                      data.item.is_active,
                                      data.item.is_online
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(actions)",
                      fn: function(data) {
                        return [
                          _c(
                            "b-dropdown",
                            {
                              attrs: {
                                variant: "link",
                                "no-caret": "",
                                right: _vm.$store.state.appConfig.isRTL
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function() {
                                      return [
                                        _c("feather-icon", {
                                          staticClass: "align-middle text-body",
                                          attrs: {
                                            icon: "MoreVerticalIcon",
                                            size: "16"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _vm.$can("update", "camerasmanagement")
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: {
                                        to: {
                                          name: "apps-cameras-management-edit",
                                          params: {
                                            cameraid: data.item.camera_id
                                          }
                                        }
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        attrs: { icon: "EditIcon" }
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "align-middle ml-50" },
                                        [_vm._v("Edit")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.$can("delete", "camerasmanagement")
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteCamera(data.item)
                                        }
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        attrs: { icon: "TrashIcon" }
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "align-middle ml-50" },
                                        [_vm._v("Delete")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1991087961
                )
              }),
              _c(
                "div",
                { staticClass: "mx-2 mb-2" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-center justify-content-sm-start",
                          attrs: { cols: "12", sm: "6" }
                        },
                        [
                          _c("span", { staticClass: "text-muted" }, [
                            _vm._v(
                              "Showing " +
                                _vm._s(_vm.dataMeta.from) +
                                " to " +
                                _vm._s(_vm.dataMeta.to) +
                                " of " +
                                _vm._s(_vm.dataMeta.of) +
                                " entries"
                            )
                          ])
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-center justify-content-sm-end",
                          attrs: { cols: "12", sm: "6" }
                        },
                        [
                          _c("b-pagination", {
                            staticClass: "mb-0 mt-1 mt-sm-0",
                            attrs: {
                              "total-rows": _vm.totalItems,
                              "per-page": _vm.perPage,
                              "first-number": "",
                              "last-number": "",
                              "prev-class": "prev-item",
                              "next-class": "next-item"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prev-text",
                                  fn: function() {
                                    return [
                                      _c("feather-icon", {
                                        attrs: {
                                          icon: "ChevronLeftIcon",
                                          size: "18"
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "next-text",
                                  fn: function() {
                                    return [
                                      _c("feather-icon", {
                                        attrs: {
                                          icon: "ChevronRightIcon",
                                          size: "18"
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              1308952388
                            ),
                            model: {
                              value: _vm.currentPage,
                              callback: function($$v) {
                                _vm.currentPage = $$v
                              },
                              expression: "currentPage"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }